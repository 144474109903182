import { motion } from "framer-motion";
import { SlideFromAbove } from "components";

export default function Card ({ children, className }) {
	return (
		<SlideFromAbove>
			<motion.div
				className={`flex items-center justify-center bg-gray-100 text-gray-900 rounded-lg shadow-lg p-5 ${className}`}
				whileHover={{ scale: 1.05, transition: { duration: 0.15 } }}
				drag
				dragConstraints={{
					top: -0,
					left: -0,
					right: 0,
					bottom: 0,
				}}
			>
				{children}
			</motion.div>
		</SlideFromAbove>
	);
}

import { TextAnim } from "components";
import { motion } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";
import { useInView } from "framer-motion";

const Terminal = ({ input }) => {
	const [currentLineRendered, setCurrentLineRendered] = useState(0);
	const ref = useRef(null)
	const isInView = useInView(ref, { once: true });

	useEffect(() => {
		const interval = setInterval(() => {
			if (currentLineRendered < input.length) {
				setCurrentLineRendered(prev => prev + 1);
			}
		}, 2000)

		return () => clearInterval(interval);
	}, [isInView]);

	console.log(isInView)

	return (
			<motion.div
				className="bg-black text-white text-2xl h-[525px] w-[1000px] overflow-auto mt-10 opacity-85"
				ref={ref}
			>
				<div className="flex items-center justify-between bg-gray-800 p-2">
					<div className="flex gap-2">
						<div className="w-4 h-4 bg-red-500 rounded-full"></div>
						<div className="w-4 h-4 bg-yellow-500 rounded-full"></div>
						<div className="w-4 h-4 bg-green-500 rounded-full"></div>
					</div>
				</div>
				{
					isInView && input.map((line, index) => {
						const className = `mx-5 my-4 ${currentLineRendered >= index ? "" : "hidden"}`;

						if (index % 2 === 0 && currentLineRendered >= index) {
							return (
								<motion.div className={className}>
									<TextAnim text={line} />
								</motion.div>
							)
						} else if (currentLineRendered >= index) {
							return (
								<p className={className}>{line}</p>
							)
						} else {
							return null;
						}
					})
				}
			</motion.div>
	)
}

export default React.memo(Terminal);

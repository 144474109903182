import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const Link = motion(NavLink);

export default function Button({ url, icon, text, redirect }) {
	return (
			<Link
				to={url}
				className={({ isActive }) => `${isActive ? 'bg-slate-100' : 'hover:bg-slate-100'} text-slate-800 text-3xl rounded-lg cursor-pointer transition-colors duration-250 ease-in-out p-2`}
				target={redirect ? '_blank' : ''}
				whileHover={{ scale: 1.15 }}
				whileTap={{ scale: 0.85 }}
				transition={{ duration: 0.15 }}
			>
				{
					<div className="flex justify-center items-center gap-1">
						{icon} {text} {redirect}
					</div>
				}
			</Link>
	)
}

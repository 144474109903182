import { Nav, Footer } from "components";
import { motion } from "framer-motion";

export default function Layout({ children }) {
	return (
		<motion.div
			className="flex flex-col pt-10 mx-96 m-auto"
		>
			<div style={{ minHeight: "300vh" }}>
				<Nav />
				<main className="my-16">
					{children}
				</main>
			</div>
			<Footer/>
		</motion.div>
	);
}

import { Layout, Terminal, terminalInput, Card, Section } from "components";
import { node, docker, linux, react, nest, ts, postgres, redis } from "assets/svg";

const Home = () => {
	const skillsIcons = [
		{
			icon: node,
			alt: "node"
		},
		{
			icon: docker,
			alt: "docker"
		},
		{
			icon: linux,
			alt: "linux"
		},
		{
			icon: react,
			alt: "react"
		},
		{
			icon: nest,
			alt: "nest"
		},
		{
			icon: ts,
			alt: "typescript"

		},
		{
			icon: postgres,
			alt: "postgres"
		},
		{
			icon: redis,
			alt: "redis"
		}
	]

	return (
		<Layout>
			<Section>
				<Card className="flex-col">
					<h1 class="text-2xl font-bold text-center mb-5">
						Hi! My Name Is <span className="text-red-500 text-3xl">Ionatan Dumea</span>.<br />And This Is My Website.
					</h1>
					<section className="text-center text-gray-700">
						<p className="mb-3">👨‍💻 I'm a Backend & DevOps Engineer, passionate about crafting innovative web solutions and solving complex problems.</p>
						<p className="mb-3">📚 I thrive on learning new technologies and frameworks, constantly expanding my skill set to stay at the forefront of industry trends.</p>
						<p className="mb-3">🌐 Feel free to explore my website and discover my latest projects.</p>
					</section>
				</Card>
			</Section>

			<Section className="flex-col">
				<Terminal input={terminalInput} />
			</Section>

			<Section>
				<Card className="flex-wrap">
					{
						skillsIcons.map((skill, index) => (
							<img key={index} src={skill.icon} alt={skill.alt} className="w-32 h-32 pointer-events-none" />
						))
					}
				</Card>
			</Section>
		</Layout>
	)
}

export default Home;

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home, About, Blog, Projects, NotFound } from "views";

const getPage = (page) => {
	let component

	switch (page) {
	case 'Home':
		component = <Home />
		break
	case 'About':
		component = <About />
		break
	case 'Blog':
		component = <Blog />
		break
	case 'Projects':
		component = <Projects />
		break
	default:
		component = <NotFound />
		break
	}

	return (
		<>
			{component}
		</>
	);
}
const App = () => {
	const router = createBrowserRouter(
		['Home', 'About', 'Blog', 'Projects'].map(page => ({
			path: page === 'Home' ? '/' : `/${page.toLowerCase()}`,
			element: getPage(page),
			errorElement: <NotFound />,
		}))
	);

	if (process.env.NODE_ENV === 'production') {
		return (
			<div className="flex justify-center items-center h-screen p-5 text-center">
				<p className="text-4xl">🚧 Pardon the 💨 dust! 🌟 Exciting changes ahead! 🚀💡</p>
			</div>
		);
	} else {
		return (
			<>
				<RouterProvider router={router} />
			</>
		);
	}
}

export default App;

import { Layout } from "components";

const Projects = () => {
	return (
		<Layout>
			<h1>Projects page</h1>
		</Layout>
	)
}

export default Projects;

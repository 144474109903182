import { Link } from "react-router-dom";

export default function ErrorPage() {
	return (
		<div className="flex justify-center items-center h-screen gap-x-5">
			<p>404 Not Found</p>
			<Link to="/" className="text-white text-sky-400 hover:text-sky-500">Home</Link>
			<Link to="/about" className="text-white text-sky-400 hover:text-sky-500">About</Link>
			<Link to="/contact" className="text-white text-sky-400 hover:text-sky-500">Contact</Link>
		</div>
	);
}

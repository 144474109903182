import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { useEffect, useState } from "react";
import CursorBlinker from "./CursorBlinker";


export default function TextAnim({ text }) {
	const count = useMotionValue(0);
	const rounded = useTransform(count, (latest) => Math.round(latest));
	const displayText = useTransform(rounded, (latest) =>
		text.slice(0, latest)
	);

	useEffect(() => {
		const controls = animate(count, text.length, {
			type: "tween",
			duration: 3,
			ease: "backOut",
		});

		return controls.stop;
	}, []);

	// Control to visiblity of the cursor
	const [isVisible, setVisible] = useState(true);
	setTimeout(() => {
		setVisible(false);
	}, 2000);

	return (
		<span>
			<motion.span>{displayText}</motion.span>
			<CursorBlinker isVisible={isVisible}/>
		</span>
	);
}

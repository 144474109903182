import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { motion } from 'framer-motion';

export default function Footer() {
	return (
		<motion.footer className="bg-gray-800 text-white py-5 opacity-85">
			<div className="container mx-auto text-center">
				<div className="flex justify-center mb-4">
					<a href="https://github.com/jonnn9" target="_blank" rel="noopener noreferrer" className="text-white mx-2 hover:text-gray-300">
						<FaGithub size={38} />
					</a>
					<a href="https://www.linkedin.com/in/dumeaionatan/" target="_blank" rel="noopener noreferrer" className="text-white mx-2 hover:text-gray-300">
						<FaLinkedin size={38} />
					</a>
				</div>
				<p className="mb-2">
					© {new Date().getFullYear()} Ionatan Dumea. All rights reserved.
				</p>
				<p className="mb-4">
					Contact: dumeaionatan9@gmail.com | Phone: +40773953705
				</p>
			</div>
		</motion.footer>
	);
}

import { Layout } from "components";

const Blog = () => {
	return (
		<Layout>
			<h1>Blog page</h1>
		</Layout>
	)
}

export default Blog;

import { Layout } from "components";
const About = () => {
	return (
		<Layout>
			<h1>About page</h1>
		</Layout>
	)
}

export default About;

import { useAnimation, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useInView } from 'framer-motion';

export default function SlideFromAbove({ children }) {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true});

	const controls = useAnimation();

	useEffect(() => {
		if (isInView) {
			controls.set({ y: -200 });
			controls.start({
				y: 0,
				transition: { duration: 1.5 },
			});
		}

		return () => controls.stop();
	}, [isInView])

	return (
		<motion.div
			animate={controls}
			ref={ref}
		>
			{children}
		</motion.div>
	)
}

import { Button } from './components';
import { MdHome, MdCode, MdEdit } from 'react-icons/md';
import { FaGithub, FaLinkedin, FaExternalLinkAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

const iconSize = 48;
const externalLinkIconSize = 16;

const navItems = [
	{
		url: '/',
		icon: <MdHome size={iconSize} />,
		text: 'Home'
	},
	{
		url: '/projects',
		icon: <MdCode size={iconSize} />,
		text: 'Projects'
	},
	{
		url: '/blog',
		icon: <MdEdit size={iconSize} />,
		text: 'Blog'
	},
	{
		url: 'https://github.com/jonnn9',
		icon: <FaGithub size={iconSize} />,
		text: 'GitHub',
		redirect: <FaExternalLinkAlt className="self-start" size={externalLinkIconSize} />
	},
	{
		url: 'https://www.linkedin.com/in/dumeaionatan/',
		icon: <FaLinkedin size={iconSize} />,
		text: 'LinkedIn',
		redirect: <FaExternalLinkAlt className="self-start" size={externalLinkIconSize} />
	}
];

const Nav = () => {
	return (
		<motion.nav
			className="flex justify-between"
		>
			{navItems.map(item => <Button url={item.url} icon={item.icon} text={item.text} redirect={item.redirect} />)}
		</motion.nav>
	);
};

export default Nav;

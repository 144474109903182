import { motion } from "framer-motion";
export default function Section({ children, className = ""}) {
	return (
		<motion.section
			style={{ height: '100vh' }}
			className={`flex items-center justify-center ${className}`}
		>
			{children}

		</motion.section>
	);
}

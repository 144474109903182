import { AnimatePresence, motion } from "framer-motion";

const cursorVariants = {
	blinking: {
		opacity: [0, 0, 1, 1],
		transition: {
			duration: 1,
			repeat: Infinity,
			repeatDelay: 0,
			ease: "linear",
			times: [0, 0.5, 0.5, 1],
		}
	}
};

export default function CursorBlinker({ isVisible }) {
	const className = `inline-block h-7 w-[10px] translate-y-1 bg-slate-900 bg-white ${isVisible ? 'visible' : 'invisible'}`;
	return (
		<AnimatePresence>
			<motion.div
				variants={cursorVariants}
				animate="blinking"
				className={className}
			/>
		</AnimatePresence>
	);
}
